import React from "react";
import "./reMaincontent.css";
import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

const REraincontent = () => {
  return (
    <ParallaxProvider>
      <div className="responsive-RenewableEnergy">
        <br />
        <div style={{ marginTop: "100px" }}>
          <div className="RenewableEnergy-pc">
            <ParallaxBanner style={{ aspectRatio: "2 / 1", width: "100%" }}>
              <ParallaxBannerLayer
                image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/renewable-energy/tile-01-a.webp"
                speed={7}
              />
            </ParallaxBanner>
          </div>
          <div className="RenewableEnergy-mobile">
            <ParallaxBanner style={{ aspectRatio: "auto 360 / 540" }}>
              <ParallaxBannerLayer
                image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/renewable-energy/tile-01-a.webp"
                speed={10}
              />
            </ParallaxBanner>
          </div>
        </div>

        <div className="first-para-RenewableEnergy">
          <div>
            <p className="para">
              Engaged in the production of wood chips, for consumption by
              industrial boilers and heaters, our three independent units
              situated in Unagahadeniya, Amithirigala, and Ratnapura source
              mixed wood and rubber wood from permit holders and RPCs. We
              continuously work in collaboration with state entities to promote
              the UNDP renewable energy program for community planting of
              firewood, thereby empowering the surrounding communities towards a
              sustainable livelihood, through its commercial sale.
              <br />
            </p>
          </div>

          <div>
            <p className="address">
              Lalan Energy Solutions (Private) Limited. <br />
              Lalan Unagahadeniya Complex, <br />
              Kamburagallawatta, <br />
              Unagahadeniya, <br />
              Ruggahawila, <br />
              Sri Lanka. <br />
              info@lalangroup.com <br />
              (+94) 33 228 1919 <br />
              <br />
            </p>
          </div>
        </div>

        <div className="RenewableEnergy-pc">
          <center>
            <h1 className="bigHugeTitle">
              9 Sectors <br />
              of Integrated <br />
              <span
                style={{
                  color: "#46708a",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                Expertise
              </span>
            </h1>
            <div style={{ marginTop: "100px", marginBottom: "200px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <div style={{ width: "2px", background: "#46708a" }}></div>
              </div>
            </div>
          </center>
        </div>

        <div
          className="RenewableEnergy-mobile"
          style={{ marginTop: "100px", marginBottom: "300px" }}
        >
          <p className="text-max-RenewableEnergy">
            9 Sectors of Integrated
            <span
              style={{
                color: "#46708a",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              Expertise
            </span>
          </p>
        </div>

        <div></div>
      </div>
    </ParallaxProvider>
  );
};

export default REraincontent;
